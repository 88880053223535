import CoverImage from '../../components/CoverImage';
import styles from './About.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import { slider, partners } from '../../data/about';

const About = () => {
    return (
        <div className={`${styles.About}`}>
            <CoverImage title="About Us" />
            <div className={`container ${styles.About__content} mt-5`}>
                <div className="row mt-5 pt-5">
                    <div className="col-12 col-md-7 mb-5">
                        {/*<Carousel controls={false} indicators={false} interval={2000}>*/}
                        {/*    {slider.map((slide) => (*/}
                        {/*        <Carousel.Item>*/}
                        {/*            <img*/}
                        {/*                className="d-block w-100"*/}
                        {/*                src={slide.source}*/}
                        {/*                alt={slide.alt}*/}
                        {/*            />*/}
                        {/*        </Carousel.Item>*/}
                        {/*    ))}*/}
                        {/*</Carousel>*/}
                        <img
                          className="d-block w-100"
                          src="/img/about/about.jpg"
                          alt="Active Edge Capital Partners"
                        />
                    </div>
                    <div className="col-12 col-md-5">
                        <h1 className="mb-3">Our Story</h1>
                        <p>
                            Active Edge Capital Partners is a South African Business Consultancy
                            focused on assisting clients design, implement and monitor, BBBEE
                            solutions that are crafted around Corporate Social Responsibility,
                            Enterprise Development and Supplier Development initiatives.
                        </p>
                        <p>
                            With over 10 years’ experience within the Broad Based Black Economic
                            Empowerment space, Active Edge Capital Partners has executed successful
                            initiatives within the following sectors Banking, Financial Services,
                            Construction, Food and Beverage, Property, and Mining.
                        </p>
                        <p>
                            Our team collectively have over 3 decades of experience working for some
                            of South Africa’s leading Corporates such as Investec, Famous Brands,
                            Stanlib Asset Management and African Bank.
                        </p>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <h1 className="mb-3">Our Partners and clients.</h1>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="row">
                            {partners.map((partner) => (
                                <div className={`${styles.About__partner}`}>
                                    <img
                                        src={partner.source}
                                        alt={partner.alt}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
